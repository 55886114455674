@import "modules/variables.scss";
@import "modules/mixins.scss";

.contact-informations-container {
    display: flex;
    width: 100%;

    .phone-column {
        width: 20%;
    }

    .mail-column, .name-column {
        width: 40%;
    }

    .column-header {
        font-weight: 700;
    }

    .column-add-row {
        font-size: 24px;
        cursor: pointer;
        margin-top: 10px;
    }

    .column-remove-row {
        font-size: 18px;
        cursor: pointer;
    }

    input[type=text] {
            border: none;
            border-bottom: 1px solid $blueSynchrone;
            height: 26px;
            width: 80%;
            font-size: 15px;
            color: $blueSynchrone;
            font-weight: 500;
            font-family: $mainFont;

            + .error-warning {
                opacity: 0;
                background-color: $corail;
                color: white;
                position: absolute;
            }

            &:hover {
                + .error-warning {
                    opacity: 1;
                    right: 135px;
                    top: 0;
                    display: block;
                    opacity: 1;
                    padding: 15px;
                    z-index: 1000;
                    transition-duration: 200ms;
                    -o-transition-duration: 200ms;
                    -moz-transition-duration: 200ms;
                    -webkit-transition-duration: 200ms;
                    transform: translate(0, 40px);
                    -o-transform: translate(0, 40px);
                    -moz-transform: translate(0, 40px);
                    -webkit-transform: translate(0, 40px);
                }
            }

            &.error {
                border-bottom: 1px solid $corail;
            }

        }
}